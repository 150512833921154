<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">
            {{ athleteInformation.athlete_name }} -
            {{ athleteInformation.current_year }} Season
          </h4>
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  placeholder="Search records"
                  v-model="searchQuery"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>
            </div>
            <el-table
              :data="queriedData"
              :header-cell-style="{ background: '#525f7f' }"
            >
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
              >
                <template slot-scope="scope">
                  {{ formatCurrency(scope.row, column.prop) }}
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import { BasePagination } from "src/components";
import Fuse from "fuse.js";

export default {
  props: {
    athleteTransactions: Array,
    athleteInformation: Object,
  },
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0 && this.searchQuery !== "") {
        result = this.searchedData;
        const items = result.map((obj) => obj.item);
        return items.slice(this.from, this.to);
      } else {
        return result.slice(this.from, this.to);
      }
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["event", "type", "event_date", "total_amount"],
      tableColumns: [
        {
          prop: "event",
          label: "Event",
          minWidth: 200,
        },
        {
          prop: "total_amount",
          label: "Transactions Paid",
          minWidth: 120,
        },
        {
          prop: "type",
          label: "Event Type",
          minWidth: 250,
        },
        {
          prop: "event_date",
          label: "Event Date",
          minWidth: 100,
        },
      ],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
    };
  },
  methods: {
    generateLink(row) {
      const athleteName = btoa(row.athleteName);
      return `/orginization/${row.school}/${athleteName}`;
    },
    formatSport(sport) {
      const sportMapping = {
        Football: "Football",
        FOOTBALL: "Football",
        MBBALL: "Mens Basketball",
        WBBALL: "Womens Basketball",
        BASE: "Baseball",
        MGOLF: "Mens Golf",
        MHOCK: "Mens Hockey",
        WLAX: "Womens Lacross",
        MSOCC: "Mens Soccer",
        MLAX: "Mens Lacross",
        MWRES: "Mens Wrestling",
        WVOLL: "Womens Volleyball",
        WTRACK: "Womens Track",
        WSOCC: "Womens Soccer",
        WHOCK: "Womens Hockey",
        WCROSS: "Womens Cross Country",
        MCROSS: "Mens Cross Country",
        SOFT: "Softball",
        WGOLF: "Womens Golf",
        MTENN: "Mens Tennis",
        WSWIM: "Womens Swin",
        WGYM: "Womens Gym",
        TRACK: "Track",
        WFHOCK: "Womens Field Hockey",
        MSWIM: "Mens Swim",
      };
      return sportMapping[sport] || "sport";
    },
    formatCurrency(value, prop) {
      if (prop !== "total_amount") return value[prop];

      return (
        "$ " +
        Number(value["total_amount"])
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      );
    },
  },
  mounted() {
    this.tableData = this.athleteTransactions;
    athleteInformation: Object;
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ["athlete_name", "school", "sport"],
      threshold: 0.3,
    });
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }

      this.searchedData = result;
    },
  },
};
</script>
<style>
.el-table__footer-wrapper .el-table_1_column_0 {
  font-weight: bold;
}

.el-table,
th.el-table__cell {
  background-color: none !important;
}

.el-table__footer-wrapper tbody td.el-table__cell,
.el-table__header-wrapper tbody td.el-table__cell {
  background: #525f7f !important;
  border: none;
}
.el-table__footer-wrapper tbody td.el-table__cell,
.el-table__header-wrapper tbody td.el-table__cell:nth-child(1) {
  background: red;
}

.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}

.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf:nth-child(1) {
  background: rgb(37, 39, 44);
}

.el-table .el-table__header-wrapper thead tr th:nth-child(1) {
  background-color: rgb(82, 95, 127) !important;
  border-right: 5px solid #1e1e2f;
  font-style: italic;
}
.el-table .el-table__footer tbody tr td:nth-child(1) {
  background-color: rgb(82, 95, 127) !important;
  /* border-right: 5px solid #1e1e2f; */
  /* font-style: italic; */
}
.el-table__row {
  cursor: pointer;
}
</style>
