import { render, staticRenderFns } from "./StudentAthlete.vue?vue&type=template&id=135b391c"
import script from "./StudentAthlete.vue?vue&type=script&lang=js"
export * from "./StudentAthlete.vue?vue&type=script&lang=js"
import style0 from "./StudentAthlete.vue?vue&type=style&index=0&id=135b391c&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports