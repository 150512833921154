<template>
  <div>
    <div class="row">
      <div class="col-4">
        <el-table
          :data="funding"
          style="width: 100%"
          size="large"
          :header-cell-style="{ background: '#525f7f' }"
          show-summary
          :summary-method="getSummaries"
        >
          <el-table-column
            prop="month"
            label="Commercial & Membership"
            width="180"
          >
          </el-table-column>
          <el-table-column
            prop="total_general_funding"
            label="Funding (net)"
            width="180"
          >
            <template slot-scope="scope">
              {{ formatNum(scope.row.total_general_funding) }}
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="total_student_athlete_transactions"
            label="Student Athlete Payments"
            width="180"
          >
            <template slot-scope="scope">
              {{ formatNum(scope.row.total_student_athlete_transactions) }}
            </template>
          </el-table-column>
          <el-table-column prop="orginization" label="2023 Balance Forward">
            <template>
              {{ formatNum(0) }}
            </template>
          </el-table-column>
          <el-table-column prop="remaining_balance" label="Remaining Balance">
            <template slot-scope="scope">
              <div v-html="formatCurrency(scope)"></div>
            </template>
          </el-table-column> -->
        </el-table>
      </div>
      <div class="col-4">
        <el-table
          :data="funding"
          style="width: 100%"
          size="large"
          :header-cell-style="{ background: '#525f7f' }"
          show-summary
          :summary-method="getSummaries"
        >
          <el-table-column prop="month" label="501c(3) Funding" width="180">
          </el-table-column>
          <el-table-column
            prop="total_donation_funding"
            label="Funding (net)"
            width="180"
          >
            <template slot-scope="scope">
              {{ formatNum(scope.row.total_donation_funding) }}
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="total_student_athlete_transactions"
            label="Student Athlete Payments"
            width="180"
          >
            <template slot-scope="scope">
              {{ formatNum(scope.row.total_student_athlete_transactions) }}
            </template>
          </el-table-column>
          <el-table-column prop="orginization" label="2023 Balance Forward">
            <template>
              {{ formatNum(0) }}
            </template>
          </el-table-column>
          <el-table-column prop="remaining_balance" label="Remaining Balance">
            <template slot-scope="scope">
              <div v-html="formatCurrency(scope)"></div>
            </template>
          </el-table-column> -->
        </el-table>
      </div>
      <div class="col-4">
        <el-table
          :data="funding"
          style="width: 100%"
          size="large"
          :header-cell-style="{ background: '#525f7f' }"
          show-summary
          :summary-method="getSummaries"
        >
          <el-table-column
            prop="month"
            label="Total Orginization Funding"
            width="180"
          >
          </el-table-column>
          <el-table-column
            prop="total_funding"
            label="Funding (net)"
            width="180"
          >
            <template slot-scope="scope">
              {{ formatNum(scope.row.total_funding) }}
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="total_student_athlete_transactions"
            label="Student Athlete Payments"
            width="180"
          >
            <template slot-scope="scope">
              {{ formatNum(scope.row.total_student_athlete_transactions) }}
            </template>
          </el-table-column>
          <el-table-column prop="orginization" label="2023 Balance Forward">
            <template>
              {{ formatNum(0) }}
            </template>
          </el-table-column>
          <el-table-column prop="remaining_balance" label="Remaining Balance">
            <template slot-scope="scope">
              <div v-html="formatCurrency(scope)"></div>
            </template>
          </el-table-column> -->
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option } from "element-ui";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    funding: Array,
  },
  data() {
    return {
      tableData: [
        {
          date: "2016-05-03",
          name: "Tom",
          address: "No. 189, Grove St, Los Angeles",
        },
        {
          date: "2016-05-02",
          name: "Tom",
          address: "No. 189, Grove St, Los Angeles",
        },
        {
          date: "2016-05-04",
          name: "Tom",
          address: "No. 189, Grove St, Los Angeles",
        },
        {
          date: "2016-05-01",
          name: "Tom",
          address: "No. 189, Grove St, Los Angeles",
        },
      ],
    };
  },
  methods: {
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "Totals";
          return;
        }
        if (index === 3) {
          // This is where we will add the remaining balance field in the future
          sums[index] = "$0.00";
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          const val = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = this.formatNum(val);
        } else {
          if (index !== 3) {
            sums[index] = "N/A";
          }
        }
      });

      return sums;
    },
    formatNum(value) {
      return (
        "$" +
        Number(value)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      );
    },
    formatCurrency(value) {
      const val = value.row.remaining_balance;

      const num =
        "$" +
        Number(val)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      const numColor = Math.sign(val) === -1 ? "text-danger" : "text-success";

      return `<span class="${numColor}"'>${num}</span>`;
    },
  },
  computed: {},
  filters: {},
};
</script>

<style>
.el-table__footer-wrapper .el-table_1_column_0 {
  font-weight: bold;
}

.el-table,
th.el-table__cell {
  background-color: none !important;
}

.el-table__footer-wrapper tbody td.el-table__cell,
.el-table__header-wrapper tbody td.el-table__cell {
  background: #525f7f !important;
  border: none;
}
.el-table__footer-wrapper tbody td.el-table__cell,
.el-table__header-wrapper tbody td.el-table__cell:nth-child(1) {
  background: red;
}

.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}

.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf:nth-child(1) {
  background: rgb(37, 39, 44);
}

.el-table .el-table__header-wrapper thead tr th:nth-child(1) {
  background-color: rgb(82, 95, 127) !important;
  border-right: 5px solid #1e1e2f;
  font-style: italic;
}
.el-table .el-table__footer tbody tr td:nth-child(1) {
  background-color: rgb(82, 95, 127) !important;
  border-right: 5px solid #1e1e2f;
  /* font-style: italic; */
}
</style>
