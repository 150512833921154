<template>
  <div class="container">
    <div class="row">
      <div class="col-md-7 mr-auto">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(submit)">
            <card class="card-register">
              <ValidationProvider
                name="fullname"
                rules="required"
                v-slot="{ passed, failed, errors }"
              >
                <base-input
                  required
                  v-model="fullname"
                  placeholder="Full Name"
                  addon-left-icon="tim-icons icon-single-02"
                  type="text"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                >
                </base-input>
              </ValidationProvider>

              <ValidationProvider
                name="email"
                rules="required|email"
                v-slot="{ passed, failed, errors }"
              >
                <base-input
                  required
                  v-model="email"
                  placeholder="Email"
                  addon-left-icon="tim-icons icon-email-85"
                  type="email"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                >
                </base-input>
              </ValidationProvider>

              <ValidationProvider
                name="password"
                rules="required"
                v-slot="{ passed, failed, errors }"
              >
                <base-input
                  required
                  v-model="password"
                  placeholder="Password"
                  addon-left-icon="tim-icons icon-lock-circle"
                  type="password"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                >
                </base-input>
                <div>
                  <label for="schools">Select Schools:</label>
                  <multiselect
                    v-model="selectedSchools"
                    :options="schools"
                    :multiple="true"
                    :searchable="false"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder="Pick some"
                    label="name"
                    track-by="id"
                    :custom-label="customLabel"
                    class="multiselect-custom"
                  >
                  </multiselect>

                  <!-- <p>Selected School IDs: {{ selectedSchoolIds }}</p> -->
                </div>
              </ValidationProvider>

              <base-button
                native-type="submit"
                slot="footer"
                type="primary"
                round
                block
                size="lg"
                @click="registerUser"
              >
                Register
              </base-button>
            </card>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
// import "vue-multiselect/dist/vue-multiselect.min.css"; // Import styles
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";

extend("email", email);
extend("required", required);

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      email: "",
      fullname: "",
      password: "",
      selectedSchools: null,
      schools: [],
    };
  },
  async mounted() {
    await this.getSchools();
  },
  methods: {
    submit() {},
    customLabel(school) {
      // Ensure selectedSchools is not null before using .includes()
      return this.selectedSchools && this.selectedSchools.includes(school)
        ? `✓ ${school.name}`
        : school.name;
    },
    registerUser() {
      const usersSchools = this.selectedSchools.map(
        (school) => school.abbreviation
      );
      const authObj = {
        email: this.email,
        name: this.fullname,
        password: this.password,
        is_admin: 0,
        schools: usersSchools,
      };
      this.$http
        .post(`${process.env.VUE_APP_BASE_URL}/api/auth/register`, authObj)
        .then((res) => {
          alert(`User Created Succesfully with password: ${this.password}`);
          this.email = "";
          this.fullname = "";
          (this.password = ""), (this.selectedSchools = null);
        })
        .catch((err) => {
          alert("There was an error registering the user");
        });
    },
    async getSchools() {
      await this.$http
        .get(`${process.env.VUE_APP_BASE_URL}/api/schools`)
        .then((res) => {
          this.schools = res.data.schools;
        })
        .catch((err) => console.log(err));
    },
  },
  computed: {
    // This will output only the IDs of the selected schools
    selectedSchoolIds() {
      return this.selectedSchools
        ? this.selectedSchools.map((school) => school.id)
        : [];
    },
  },
};
</script>
<style>
.multiselect-custom .multiselect__option--selected {
  background-color: #007bff; /* Blue background for selected items */
  color: red; /* White text for selected items */
}

.multiselect-custom .multiselect__option {
  padding-left: 20px;
  color: white;
}

.multiselect-custom .multiselect__option:before {
  content: " ";
  display: inline-block;
  margin-right: 10px;
  width: 10px;
  height: 10px;
  background-color: transparent;
}

.multiselect-custom .multiselect__option--selected:before {
  border-radius: 50%;
}
.multiselect-custom .multiselect__content {
  max-height: 200px; /* Set maximum height */
  overflow-y: auto; /* Enable scrolling */
}
</style>
